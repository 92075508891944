import React from "react";
import HashLink from "react-anchor-link-smooth-scroll";
// import logo from "../../images/logo/logo.png";
// import in1 from "../../images/instragram/1.jpg";
// import in2 from "../../images/instragram/2.jpg";
// import in3 from "../../images/instragram/3.jpg";
// import in4 from "../../images/instragram/4.jpg";
// import in5 from "../../images/instragram/5.jpg";
// import in6 from "../../images/instragram/6.jpg";
import "./style.css";
import { observer } from "mobx-react-lite";
import { translationStore } from "../../custom/translation.store";

const FooterSection = () => {
  const t = translationStore.translationMap;

  return (
    <div className="wpo-footer-area">
      {/* <div className="wpo-footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
              <div className="wpo-footer-logo">
                <img src={logo} alt="" />
              </div>
              <p>
                There are many variations of Lorem passages of Lorem Ipsum
                available, but the majority
              </p>
              <p>By injected humour, or randomised words</p>
              <div className="social">
                <ul className="d-flex">
                  <li>
                    <HashLink href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
              <div className="footer-link">
                <h3>Quick Link</h3>
                <ul>
                  <li>
                    <HashLink href="#about">About Us</HashLink>
                  </li>
                  <li>
                    <HashLink href="#services">Services</HashLink>
                  </li>
                  <li>
                    <HashLink href="#features">Features</HashLink>
                  </li>
                  <li>
                    <HashLink href="#partners">Partners</HashLink>
                  </li>
                  <li>
                    <HashLink href="#contacts">Contact</HashLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 footer-b">
              <div className="Recent-News-area">
                <h3>Recent News</h3>
                <div className="resent-sub">
                  <p>There are many variations of passages of Lorem</p>
                  <span>
                    <i className="fa fa-clock-o" aria-hidden="true"></i> Octobor
                    10, 2018
                  </span>
                </div>
                <p>There are many variations of passages of Lorem</p>
                <span>
                  <i className="fa fa-clock-o" aria-hidden="true"></i> Octobor
                  10, 2018
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer-widget instagram">
                <h3>Instagram</h3>
                <ul className="d-flex">
                  <li>
                    <HashLink href="#">
                      <img src={in1} alt="" />
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <img src={in2} alt="" />
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <img src={in3} alt="" />
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <img src={in4} alt="" />
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <img src={in5} alt="" />
                    </HashLink>
                  </li>
                  <li>
                    <HashLink href="#">
                      <img src={in6} alt="" />
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="wpo-footer-bottom">
        <div className="container">
          <div className="wpo-footer-bottom-content">
            <div className="row">
              <div className="col-12">
                <div className="social" style={{ paddingBottom: 32 }}>
                  <ul className="d-flex justify-content-center">
                    <li>
                      <HashLink href="#">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </HashLink>
                    </li>
                    <li>
                      <HashLink href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </HashLink>
                    </li>

                    <li>
                      <HashLink href="#">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </HashLink>
                    </li>
                    <li>
                      <HashLink href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span>{t.footer_copyrights}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(FooterSection);
