import React from "react";
import "./styles.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

export const PartnersLikeMapSection = observer(() => {
  const t = translationStore.translationMap;

  const partners = [
    "Hayat group",
    "Қолинҳои мавлонои руми",
    "Defacto",
    "Усмон нақлиёт",
  ];

  return (
    <div className="custom-map-section custom-partners">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-9 about-wrap "
            style={{
              background: "#142440aa",
              padding: "50px",
              color: "white",
              backdropFilter: "blur(1px)",
            }}
          >
            <h2
              style={{
                fontSize: 36,
                fontWeight: 700,
              }}
            >
              {t.partners_title}
            </h2>

            <ul style={{ textTransform: "uppercase" }}>
              {partners.map((el) => (
                <li>{el}</li>
              ))}
            </ul>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
    </div>
  );
});
