import React from "react";

import "./style.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

const Contactpage = () => {
  const t = translationStore.translationMap;

  return (
    <div className="contact-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="contact-page-item">
              <h2>{t.contacts_title}</h2>
              <p>{t.contacts_p}</p>
              <div className="adress">
                <h3>{t.contacts_address_title}</h3>
                <span>{t.contacts_address_content}</span>
              </div>
              <div className="phone">
                <h3>{t.contacts_tel_title}</h3>
                <a href="tel:+992918616161">
                  <span>{t.contacts_tel_content_1}</span>
                </a>
                <a href="tel:+992918616161">
                  <span>{t.contacts_tel_content_2}</span>
                </a>
              </div>
              <div className="email">
                <h3>{t.contacts_email_title}</h3>
                <span>{t.contacts_email_content_1}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="contact-map mt-0">
              <iframe
                title="db"
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57763.58882182253!2d55.38442113562169!3d25.195692423227655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2z4Kam4KeB4Kas4Ka-4KaHIC0g4Kam4KeB4Kas4Ka-4KaHIOCmhuCmruCmv-CmsOCmvuCmpCAtIOCmuOCmguCmr-CngeCmleCnjeCmpCDgpobgprDgpqwg4KaG4Kau4Ka_4Kaw4Ka-4Kak!5e0!3m2!1sbn!2sbd!4v1540725271741"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4671.450989286744!2d68.76000212252787!3d38.60702404316934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzjCsDM2JzI4LjIiTiA2OMKwNDUnNTEuMCJF!5e0!3m2!1sru!2s!4v1706496042830!5m2!1sru!2s"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Contactpage);
