import React from "react";
// import signatureImg from "../../images/about/signature.png";
import abimg3 from "../../images/about/3.jpg";
import VideoModal from "../../components/ModalVideo";
import "./style.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

const AboutSection3 = () => {
  const t = translationStore.translationMap;

  return (
    <div className="wpo-about-style-3" style={{ paddingTop: 0 }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 about-wrap">
            <div className="wpo-about-content">
              <h2>{t.about_title}</h2>
              <p>
                <b>{t.about_p1b}</b>
                {t.about_p1}
              </p>
              <p>{t.about_p2}</p>
              <p>{t.about_p3}</p>
            </div>
          </div>
          <div className="col-lg-6 about-ss">
            <div className="about-title">
              <div className="img-holder">
                <img src={abimg3} alt="" />
                <div className="social-1st2">
                  <ul>
                    <li>
                      <VideoModal />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AboutSection3);
