import React from "react";
import HashLink from "react-anchor-link-smooth-scroll";
import MobileMenu from "../../components/MobileMenu";

import "./style.css";
import { TranslationBar } from "../../custom/TranslationBar";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";
import { SvgLogo } from "../../custom/SvgLogo";

const Header = () => {
  const t = translationStore.translationMap;

  return (
    <header>
      <div className="header-top-1">
        <div className="container">
          <div
            className="row"
            style={{
              gap: "18px 0",
            }}
          >
            <div
              className="col-lg-5 col-md-5 col-sm-12 col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ul className="d-flex account_login-area">
                <li>
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                  {t.hat_timeline}
                </li>
                <li>
                  <i className="fa fa-map-marker"></i>
                  {t.hat_address}
                </li>
              </ul>
            </div>
            <div
              className="offset-lg-2 offset-md-1 col-lg-5 col-md-5 col-sm-12 mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TranslationBar />
            </div>
            {/* <div
              className="col-lg-3 col-md-2 col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="btn-style">
                <a href="tel:+992918616161">{t.contact_us}</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="header-style-1" style={{ backdropFilter: "blur(10px)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
              <div
                className="logo"
                // style={{ margin: 0 }}
              >
                <HashLink href="#">
                  <div
                    style={{
                      // fontSize: 640,
                      // lineHeight: 0,
                      // transform: "translate(-10px) scale(1.8)",
                      filter: "invert(1)",
                    }}
                  >
                    <SvgLogo height="60" />
                  </div>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-9 d-none d-lg-block col-m">
              <div className="main-menu">
                <nav className="nav_mobile_menu">
                  <ul>
                    {/* <li className="active">
                      <Link to="/">Home</Link>
                      <ul className="submenu">
                        <li className="active">
                          <Link to="/">Home One</Link>
                        </li>
                        <li>
                          <Link to="/home2">Home Two</Link>
                        </li>
                        <li>
                          <Link to="/home3">Home Three</Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <HashLink href="#about">{t.header_about}</HashLink>
                    </li>
                    <li>
                      <HashLink href="#services">{t.header_services}</HashLink>
                      {/* <ul className="submenu">
                        <li>
                          <Link to="/servicesingle">service single</Link>
                        </li>
                        <li>
                          <Link to="/freight">Air Freight</Link>
                        </li>
                        <li>
                          <Link to="/road">Road Freight</Link>
                        </li>
                        <li>
                          <Link to="/ocean">Ocean Freight</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <HashLink href="#map">{t.header_map}</HashLink>
                    </li>

                    <li>
                      <HashLink href="#features">{t.header_features}</HashLink>
                      {/* <ul className="submenu">
                        <li>
                          <Link to="/blog">Blog with sidebar</Link>
                        </li>
                        <li>
                          <Link to="/blog-fullwidth">Blog full width</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">Blog single sidebar</Link>
                        </li>
                        <li>
                          <Link to="/blog-single-fullwidth">
                            Blog single fullwidth
                          </Link>
                        </li>
                      </ul> */}
                    </li>

                    <li>
                      <HashLink href="#team">{t.header_team}</HashLink>
                    </li>

                    <li>
                      <HashLink href="#contacts">{t.header_contacts}</HashLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/* <div className="col-lg-1 col-md-1 col-sm-1 col-2 search">
              <ul>
                <li>
                  <Link to="/">
                    <i className="fa fa-search"></i>
                  </Link>
                  <ul>
                    <li>
                      <form onSubmit={SubmitHandler}>
                        <input type="text" placeholder="search here.." />
                        <button type="btn">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </li>
                  </ul>
                </li>
              </ul>
            </div> */}
            <div className="col-md-1 col-sm-1 col-2">
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default observer(Header);
