import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
// import { Link } from "react-router-dom";
import HashLink from "react-anchor-link-smooth-scroll";

import PerfectScrollbar from "react-perfect-scrollbar";

import "./style.css";

import "react-perfect-scrollbar/dist/css/styles.css";
import { translationStore } from "../../custom/translation.store";

const t = translationStore.translationMap;
const menus = [
  {
    id: 1,
    title: t.header_about,
    link: "#about",
    // submenu: [
    //   {
    //     id: 11,
    //     title: "Home One",
    //     link: "/home",
    //   },
    //   {
    //     id: 12,
    //     title: "Home One 2",
    //     link: "/home2",
    //   },
    //   {
    //     id: 13,
    //     title: "Home One 3",
    //     link: "/home3",
    //   },
    // ],
  },
  // {
  //   id: 2,
  //   title: "About Us",
  //   link: "/about",
  // },
  {
    id: 3,
    title: t.header_services,
    link: "#services",
    // submenu: [
    //   {
    //     id: 31,
    //     title: "service single",
    //     link: "/servicesingle",
    //   },
    //   {
    //     id: 32,
    //     title: "Air Freight",
    //     link: "/freight",
    //   },
    //   {
    //     id: 33,
    //     title: "Road Freight",
    //     link: "/road",
    //   },
    //   {
    //     id: 34,
    //     title: "Ocean Freight",
    //     link: "/ocean",
    //   },
    // ],
  },
  {
    id: 4,
    title: t.header_map,
    link: "#map",
  },
  {
    id: 5,
    title: t.header_features,
    link: "#features",
    // submenu: [
    //   {
    //     id: 41,
    //     title: "Blog with sidebar",
    //     link: "/blog",
    //   },
    //   {
    //     id: 42,
    //     title: "Blog full width",
    //     link: "/blog-fullwidth",
    //   },
    //   {
    //     id: 43,
    //     title: "Blog single sidebar",
    //     link: "/blog-single",
    //   },
    //   {
    //     id: 44,
    //     title: "Blog single fullwidth",
    //     link: "/blog-single-fullwidth",
    //   },
    // ],
  },
  {
    id: 6,
    title: t.header_team,
    link: "#team",
  },
  // {
  //   id: 7,
  //   title: "Pages",
  //   submenu: [
  //     {
  //       id: 71,
  //       title: "pricing table",
  //       link: "/pricing",
  //     },
  //     {
  //       id: 72,
  //       title: "Team",
  //       link: "/team",
  //     },
  //   ],
  // },
  {
    id: 7,
    title: t.header_contacts,
    link: "#contacts",
  },
];

class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;

    return (
      <div>
        <PerfectScrollbar>
          <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
            {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

            <ul className="responsivemenu">
              {menus.map((item) => {
                return (
                  <li key={item.id}>
                    {item.submenu ? (
                      <p onClick={this.setIsOpen(item.id)}>
                        {item.title}
                        {item.submenu ? (
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      <HashLink href={item.link}>{item.title}</HashLink>
                    )}
                    {item.submenu ? (
                      <Collapse isOpen={item.id === isOpen}>
                        <Card>
                          <CardBody>
                            <ul>
                              {item.submenu.map((submenu) => (
                                <li key={submenu.id}>
                                  <HashLink
                                    className="active"
                                    href={submenu.link}
                                  >
                                    {submenu.title}
                                  </HashLink>
                                </li>
                              ))}
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </PerfectScrollbar>
        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}

export default MobileMenu;
