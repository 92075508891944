import { observable } from "mobx";

export const SUPPORTED_LANGUAGES = [
  "tajik",
  "russian",
  "english",
  "iranian",
  "turkish",
  "chinese",
];

const translationMap = {
  tajik: {
    tajik: "тоҷикӣ",
    russian: "русский",
    english: "english",
    iranian: "ایرانی",
    turkish: "türkçe",
    chinese: "中文",

    why_us: "ЧАРО МО?",

    header_about: "дар бораи мо",
    header_services: "хизматҳо",
    header_map: "кишварҳо",
    header_features: "афзалиятхо",
    header_news: "ахбор",
    header_team: "даста",
    header_contacts: "алоқаҳо",

    hat_timeline: "Дш - Ҷм: аз 6:00 то 22:00",
    hat_address: "кӯчаи Лучоби поён, Душанбе, Тоҷикистон",

    contact_us: "БА МО ЗАНГ ЗАНЕД",

    hero_top: "Мо борҳои шуморо мебарем",
    hero_bottom: "Тез ва бехатар.",

    hero_features_sea: "Нақлиёти баҳр",
    hero_features_rails: "Нақлиёти роҳи оҳан",
    hero_features_avia: "Нақлиёти ҳавоӣ",
    hero_features_road: "Нақлиёти автомобилӣ",
    hero_features_warehousing: "Нигоҳдории мол",
    hero_features_logistics: "Ҳалли логистика",

    about_title: "Мо кистем?",
    about_p1:
      "- ширкати ҷавони нақлиётӣ ҳастем, ки дар саросари ҷаҳон хидматрасонии логистикиро пешкаш мекунад.",
    about_p1b: "SHAHIN ASIA",
    about_p2:
      "Мо ҳамлу нақли борҳои ҳама гуна мураккабиро, аз ҷумла ҳамлу нақли инфиродӣ ва гурӯҳӣ, инчунин ҳамлу нақли мултимодалиро пешниҳод менамоем. Мо бо шахсони ҳуқуқӣ ва воқеӣ кор карда, хидматрасонии интиқоли молҳоро ба мағозаҳо ва интиқоли хона ба хона пешниҳод мекунем. Мо як давраи пурраи ҳамлу нақли бор, аз ҷумла бастабандӣ, суғурта ва нигоҳдории молро кафолат медиҳем. Бо интихоби SHAHIN ASIA, шумо метавонед ба сифати хидматрасонии мо ва эътимоднокии интиқолдиҳандагон боварӣ дошта бошед. Бо мо тамос гиред ва худатон эътимод ва сифати моро бубинед!",
    about_p3:
      "Бо интихоби мо, шумо метавонед ба сифати хизматрасониҳои мо ва эътимоднокии интиқолдиҳандагон боварӣ дошта бошед. Бо мо тамос гиред ва худатон эътимод ва сифати моро бубинед!",

    services_title: "Хизматҳои мо",

    services_li_auto: "Нақлиёти автомобилӣ",
    services_li_sea: "Нақлиёти баҳр",
    services_li_avia: "Нақлиёти ҳавоӣ",
    services_li_rails: "Нақлиёти роҳи оҳан",
    services_li_express: "Интиқоли экспресс",
    services_li_packaging: "Бастабандӣ ва нигоҳдорӣ",
    services_li_delivery: "Интиқол ва ҷамъоварӣ",
    services_li_warehousing: "Анбор",
    services_li_logistics: "Ҳалли логистика",
    services_li_insurance: "Суғурта ва барасмиятдарории гумрукӣ",
    services_li_trading: "Агентии савдо",
    services_li_multimodal: "Интиқоли мултимодалӣ",

    map_title: "Кишварҳое, ки мо ба онҳо интиқол медиҳем:",

    map_tj: "Тоҷикистон",
    map_cn: "Хитой",
    map_tr: "Туркия",
    map_ir: "Эрон",
    map_ru: "Русия",
    map_kz: "Қазоқистон",
    map_ae: "УА",
    map_us: "ИМА",
    map_in: "Ҳиндустон",
    map_pk: "Покистон",
    map_eu: "Аврупо",
    map_other: "ва дигарон...",

    partners_title: "Ба мо бовар мекунанд",

    partners_1_fullname: "Сафоев Фируз",
    partners_1_job: 'Директор "Армугони Эрон"',
    partners_1_citation:
      "Мы сотрудничаем с данной логистической компанией уже несколько лет и всегда остаемся довольными качеством и оперативностью их услуг. Команда профессионалов всегда готова помочь и предложить оптимальные решения для наших логистических задач.",

    partners_2_fullname: "Сомон Бакиев",
    partners_2_job: 'Менеджер по продажам в "PEK. Hayat group"',
    partners_2_citation:
      "На протяжении нескольких лет мы сотрудничаем с логистической компанией, и мы полностью удовлетворены качеством их услуг. Надежные и оперативные поставки, а также высокий уровень обслуживания делают их нашим долгосрочным партнером.",

    features_title: "Афзалиятхои мо",

    features_professionalism_title: "Гурӯҳи касбии мутахассисони логистика.",
    features_professionalism_description:
      "Ба саволҳои шумо мутахассисони ботаҷриба ва донишманди логистика ҷавоб хоҳанд дод",

    features_experience_title: "Таҷрибаи бой бо намудҳои гуногуни бор.",
    features_experience_description:
      "Мизоҷони мо бузургтарин корхонаҳои тиҷоратӣ, истеҳсолӣ ва сохтмонӣ мебошанд",

    features_approach_title: "Муносибати инфиродӣ ба ХАР ЯК муштарӣ.",
    features_approach_description:
      "Як менеҷер тамоми ниёзҳои муштариро дар соҳаи нақлиёт ва логистика иҷро мекунад",

    features_responsibility_title: "Масъулият.",
    features_responsibility_description:
      "Бо мақсади рушд, мо ба таври возеҳ ба мизоҷони худ ҳисси бехатарӣ медиҳем, ки онҳо метавонанд ба онҳо такя кунанд",

    team_title: "Дастаи мо",
    team_member_1_fullname: "Амир Шанехей",
    team_member_1_job: "Муовини директори генералй",
    team_member_1_tel: "(+992) 888 88 86 65",
    team_member_1_tel_extra: "(+98) 912 307 88 38",
    team_member_1_email: "shanehei@shahinasia.com",
    team_member_2_fullname: "Умедҷон Хасанзода",
    team_member_2_job: "Муассис ва директори генералӣ",
    team_member_2_tel: "-",
    team_member_2_email: "hasanzoda@shahinasia.com",
    team_member_3_fullname: "Худоёров Ҷамшед",
    team_member_3_job: "Мудири фурӯш",
    team_member_3_tel: "(+992) 970 48 65 48",
    team_member_3_email: "khudoyarov.j@shahinasia.com",
    team_member_4_fullname: "Худоёров Шохрух",
    team_member_4_job: "Менеҷери логистика",
    team_member_4_tel: "(+992) 920 20 20 20",
    team_member_4_email: "khudoyarov.sh@shahinasia.com",
    team_member_5_fullname: "Худоёров Диловар",
    team_member_5_job: "Мудири фурӯш",
    team_member_5_tel: "(+992) 100 22 22 65",
    team_member_5_email: "khudoyarov.d@shahinasia.com",
    team_member_6_fullname: "Худоёров Сомон",
    team_member_6_job: "Менеҷери логистика",
    team_member_6_tel: "(+992) 220 20 20 20",
    team_member_6_email: "khudoyarov.s@shahinasia.com",
    team_member_7_fullname: "Бадалов Абдуллох",
    team_member_7_job: "Мудири анбор",
    team_member_7_tel: "(+992) 907 40 20 02",
    team_member_7_email: "badalov.a@shahinasia.com",

    team_sup_title2: "Ҷустуҷӯи кор ҳастӣ?",
    team_title2: "Ҷойҳои холии мо",

    team_vacancy_1: "Менеҷери логистика",
    team_vacancy_2: "Мудири фурӯш",
    team_vacancy_3: "Менеҷери SMM",
    team_vacancy_4: "Банақшагири талабот",
    team_vacancy_5: "Мутахассиси назорати инвентаризатсия",
    team_vacancy_6: "Менеҷери назорати гумрукӣ",
    team_vacancy_7: "Корманди интиқол ва қабул",
    team_vacancy_8: "Мудири анбор",

    contacts_title: "Алоқаҳои мо",
    contacts_p:
      "Бо мо тавассути телефон ё почтаи электронӣ тамос гиред, мутахассисони мо омодаанд дар ҳалли мушкилоти шумо кӯмак расонанд.",
    contacts_address_title: "Суроға",
    contacts_address_content: "кӯчаи Лучоби поён, Душанбе, Тоҷикистон",
    contacts_tel_title: "Рақамҳои телефон",
    contacts_tel_content_1: "+992-98-812-3456",
    contacts_tel_content_2: "+992-91-812-3456",
    contacts_email_title: "Почта",
    contacts_email_content_1: "info@shahinasia.com",

    footer_copyrights: "© Copyrights 2024. All Rights Reserved.",
  },
  russian: {
    tajik: "тоҷикӣ",
    russian: "русский",
    english: "english",
    iranian: "ایرانی",
    turkish: "türkçe",
    chinese: "中文",

    why_us: "ПОЧЕМУ МЫ?",

    header_about: "о нас",
    header_services: "сервисы",
    header_map: "страны",
    header_features: "приемущества",
    header_news: "новости",
    header_team: "команда",
    header_contacts: "контакты",

    hat_timeline: "Пн - Пт: с 6:00 до 22:00",
    hat_address: "ул. Лучоби поён, Душанбе, Таджикистан",

    contact_us: "ПОЗВОНИТЕ НАМ",

    hero_top: "Мы Везём Ваши Грузы",
    hero_bottom: "Быстро и Безопасно.",

    hero_features_sea: "Морские перевозки",
    hero_features_rails: "Железнодорожные перевозки",
    hero_features_avia: "Авиа перевозки",
    hero_features_road: "Автомобильные перевозки",
    hero_features_warehousing: "Хранение товаров",
    hero_features_logistics: "Логистические решения",

    about_title: "Кто мы?",
    about_p1:
      "- молодая транспортная компания, предоставляющая услуги логистики по всему миру.",
    about_p1b: "SHAHIN ASIA",
    about_p2:
      "Мы предлагаем грузоперевозки любой сложности, включая индивидуальные и сборные перевозки, а также мультимодальные грузоперевозки. Мы работаем с юридическими и физическими лицами, предлагая услуги доставки товаров в магазины и доставку от двери до двери. Мы гарантируем полный цикл грузоперевозок, включая упаковку, страхование и хранение грузов. Выбирая SHAHIN ASIA, вы можете быть уверены в качестве наших услуг и надежности перевозчиков. Обращайтесь к нам и убедитесь сами в нашей надежности и качестве!",
    about_p3:
      "Выбирая нас, вы можете быть уверены в качестве наших услуг и надежности перевозчиков. Обращайтесь к нам и убедитесь сами в нашей надежности и качестве!",

    services_title: "Наши услуги",

    services_li_auto: "Автомобильные перевозки",
    services_li_sea: "Морские перевозки",
    services_li_avia: "Авиа перевозки",
    services_li_rails: "Железнодорожные перевозки",
    services_li_express: "Экспресс перевозки",
    services_li_packaging: "Упаковка и хранение",
    services_li_delivery: "Доставка и забор",
    services_li_warehousing: "Складирование",
    services_li_logistics: "Логистические решения",
    services_li_insurance: "Страхование и таможенное оформление",
    services_li_trading: "Торговое агентирование",
    services_li_multimodal: "Мультимодальные перевозки",

    map_title: "Страны в которые мы доставляем:",

    map_tj: "Таджикистан",
    map_cn: "Китай",
    map_tr: "Турция",
    map_ir: "Иран",
    map_ru: "Россия",
    map_kz: "Казахстан",
    map_ae: "ОАЭ",
    map_us: "США",
    map_in: "Индия",
    map_pk: "Пакистан",
    map_eu: "Европа",
    map_other: "и другие...",

    partners_title: "Нам доверяют",

    partners_1_fullname: "Сафоев Фируз",
    partners_1_job: 'Директор "Армугони Эрон"',
    partners_1_citation:
      "Мы сотрудничаем с данной логистической компанией уже несколько лет и всегда остаемся довольными качеством и оперативностью их услуг. Команда профессионалов всегда готова помочь и предложить оптимальные решения для наших логистических задач.",

    partners_2_fullname: "Сомон Бакиев",
    partners_2_job: 'Менеджер по продажам в "PEK. Hayat group"',
    partners_2_citation:
      "На протяжении нескольких лет мы сотрудничаем с логистической компанией, и мы полностью удовлетворены качеством их услуг. Надежные и оперативные поставки, а также высокий уровень обслуживания делают их нашим долгосрочным партнером.",

    features_title: "Наши приемущества",

    features_professionalism_title:
      "Профессиональная команда специалистов по логистике.",
    features_professionalism_description:
      "Опытные и знающие специалисты по логистике ответят на Ваши вопросы",

    features_experience_title: "Богатый опыт с разного рода грузами.",
    features_experience_description:
      "Наши клиенты - крупнейшие торговые, производственные и строительные предприятия",

    features_approach_title: "Индивидуальный подход к КАЖДОМУ клиенту.",
    features_approach_description:
      "Один менеджер реализует все нужды клиента в сфере транспорта и логистики",

    features_responsibility_title: "Ответственность.",
    features_responsibility_description:
      "Чтобы развиваться, мы ясно определяем нашим клиентам ощущение безопасности, на которое можно положиться",

    team_title: "Наша команда",
    team_member_1_fullname: "Амир Шанехей",
    team_member_1_job: "Зам. гендиректора",
    team_member_1_tel: "(+992) 888 88 86 65",
    team_member_1_tel_extra: "(+98) 912 307 88 38",
    team_member_1_email: "shanehei@shahinasia.com",
    team_member_2_fullname: "Умедчон Хасанзода",
    team_member_2_job: "Основатель и гендиректор",
    team_member_2_tel: "-",
    team_member_2_email: "hasanzoda@shahinasia.com",
    team_member_3_fullname: "Худоёров Джамшед",
    team_member_3_job: "Менеджер по продажам",
    team_member_3_tel: "(+992) 970 48 65 48",
    team_member_3_email: "khudoyarov.j@shahinasia.com",
    team_member_4_fullname: "Худоёров Шохрух",
    team_member_4_job: "Логистический менеджер",
    team_member_4_tel: "(+992) 920 20 20 20",
    team_member_4_email: "khudoyarov.sh@shahinasia.com",
    team_member_5_fullname: "Худоёров Диловар",
    team_member_5_job: "Менеджер по продажам",
    team_member_5_tel: "(+992) 100 22 22 65",
    team_member_5_email: "khudoyarov.d@shahinasia.com",
    team_member_6_fullname: "Худоёров Сомон",
    team_member_6_job: "Логистический менеджер",
    team_member_6_tel: "(+992) 220 20 20 20",
    team_member_6_email: "khudoyarov.s@shahinasia.com",
    team_member_7_fullname: "Абдуллох Бадалов",
    team_member_7_job: "Заведующий складом",
    team_member_7_tel: "(+992) 907 40 20 02",
    team_member_7_email: "badalov.a@shahinasia.com",

    team_sup_title2: "ИЩЕШЬ РАБОТУ?",
    team_title2: "Наши вакансии",

    team_vacancy_1: "Менеджер по логистике",
    team_vacancy_2: "Менеджер по продажам",
    team_vacancy_3: "Менеджер по SMM",
    team_vacancy_4: "Планировщик спроса",
    team_vacancy_5: "Специалист по инвентарному контролю",
    team_vacancy_6: "Менеджер по таможенному контролю",
    team_vacancy_7: "Сотрудник по доставке и приемке",
    team_vacancy_8: "Заведующий складом",

    contacts_title: "Наши контакты",
    contacts_p:
      "Свяжитесь с нами по телефону или по электронной почте, наши профессиональны готовы помочь с решением ваших задач.",
    contacts_address_title: "Адрес",
    contacts_address_content: "ул. Лучоби поён, Душанбе, Таджикистан",
    contacts_tel_title: "Телефонные номера",
    contacts_tel_content_1: "+992-98-812-3456",
    contacts_tel_content_2: "+992-91-812-3456",
    contacts_email_title: "Почта",
    contacts_email_content_1: "info@shahinasia.com",

    footer_copyrights: "© Copyrights 2024. All Rights Reserved.",
  },
  english: {
    tajik: "тоҷикӣ",
    russian: "русский",
    english: "english",
    iranian: "ایرانی",
    turkish: "türkçe",
    chinese: "中文",

    why_us: "WHY US?",

    header_about: "about us",
    header_services: "services",
    header_map: "countries",
    header_features: "advantages",
    header_news: "news",
    header_team: "team",
    header_contacts: "contacts",

    hat_timeline: "Mn - Fr: from 6:00 to 22:00",
    hat_address: "Luchobi poyon street, Dushanbe, Tajikistan",

    contact_us: "CALL US",

    hero_top: "We Carry Your Cargoes",
    hero_bottom: "Fast and Safe.",

    hero_features_sea: "Shipping",
    hero_features_rails: "Rail transportation",
    hero_features_avia: "Air transportation",
    hero_features_road: "Road transport",
    hero_features_warehousing: "Storage of goods",
    hero_features_logistics: "Logistics solutions",

    about_title: "Who are we?",
    about_p1:
      "- a young transport company providing logistics services around the world.",
    about_p1b: "SHAHIN ASIA",
    about_p2:
      "We offer cargo transportation of any complexity, including individual and groupage transportation, as well as multimodal cargo transportation. We work with legal entities and individuals, offering goods delivery services to stores and door-to-door delivery. We guarantee a full cycle of cargo transportation, including packaging, insurance and storage of goods. By choosing SHAHIN ASIA, you can be confident in the quality of our services and the reliability of carriers. Contact us and see for yourself our reliability and quality!",
    about_p3:
      "By choosing us, you can be confident in the quality of our services and the reliability of carriers. Contact us and see for yourself our reliability and quality!",

    services_title: "Our services",

    services_li_auto: "Road transport",
    services_li_sea: "Shipping",
    services_li_avia: "Air transportation",
    services_li_rails: "Rail transportation",
    services_li_express: "Express transportation",
    services_li_packaging: "Packaging and storage",
    services_li_delivery: "Delivery and collection",
    services_li_warehousing: "Warehousing",
    services_li_logistics: "Logistics solutions",
    services_li_insurance: "Insurance and customs clearance",
    services_li_trading: "Trade agency",
    services_li_multimodal: "Multimodal transportation",

    map_title: "Countries we deliver to:",

    map_tj: "Tajikistan",
    map_cn: "China",
    map_tr: "Turkey",
    map_ir: "Iran",
    map_ru: "Russia",
    map_kz: "Kazakhstan",
    map_ae: "UAE",
    map_us: "USA",
    map_in: "India",
    map_pk: "Pakistan",
    map_eu: "Europe",
    map_other: "and others...",

    partners_title: "We are trusted by",

    partners_1_fullname: "Сафоев Фируз",
    partners_1_job: 'Директор "Армугони Эрон"',
    partners_1_citation:
      "Мы сотрудничаем с данной логистической компанией уже несколько лет и всегда остаемся довольными качеством и оперативностью их услуг. Команда профессионалов всегда готова помочь и предложить оптимальные решения для наших логистических задач.",

    partners_2_fullname: "Сомон Бакиев",
    partners_2_job: 'Менеджер по продажам в "PEK. Hayat group"',
    partners_2_citation:
      "На протяжении нескольких лет мы сотрудничаем с логистической компанией, и мы полностью удовлетворены качеством их услуг. Надежные и оперативные поставки, а также высокий уровень обслуживания делают их нашим долгосрочным партнером.",

    features_title: "Our advantages",

    features_professionalism_title:
      "Professional team of logistics specialists.",
    features_professionalism_description:
      "Experienced and knowledgeable logistics specialists will answer your questions",

    features_experience_title: "Rich experience with various types of cargo.",
    features_experience_description:
      "Our clients are the largest trading, manufacturing and construction enterprises",

    features_approach_title: "Individual approach to each client.",
    features_approach_description:
      "One manager fulfills all the client’s needs in the field of transport and logistics",

    features_responsibility_title: "Responsibility.",
    features_responsibility_description:
      "In order to grow, we clearly provide our customers with a sense of security they can rely on",

    team_title: "Our team",
    team_member_1_fullname: "Amir Shanehei",
    team_member_1_job: "Deputy CEO",
    team_member_1_tel: "(+992) 888 88 86 65",
    team_member_1_tel_extra: "(+98) 912 307 88 38",
    team_member_1_email: "shanehei@shahinasia.com",
    team_member_2_fullname: "Umedjon Hasanzoda",
    team_member_2_job: "Founder and CEO",
    team_member_2_tel: "-",
    team_member_2_email: "hasanzoda@shahinasia.com",
    team_member_3_fullname: "Khudoyarov Jamshed",
    team_member_3_job: "Sales manager",
    team_member_3_tel: "(+992) 970 48 65 48",
    team_member_3_email: "khudoyarov.j@shahinasia.com",
    team_member_4_fullname: "Khudoyarov Shohruh",
    team_member_4_job: "Logistics manager",
    team_member_4_tel: "(+992) 920 20 20 20",
    team_member_4_email: "khudoyarov.sh@shahinasia.com",
    team_member_5_fullname: "Khudoyarov Dilovar",
    team_member_5_job: "Sales manager",
    team_member_5_tel: "(+992) 100 22 22 65",
    team_member_5_email: "khudoyarov.d@shahinasia.com",
    team_member_6_fullname: "Khudoyarov Somon",
    team_member_6_job: "Logistics manager",
    team_member_6_tel: "(+992) 220 20 20 20",
    team_member_6_email: "khudoyarov.s@shahinasia.com",
    team_member_7_fullname: "Badalov Abdulloh",
    team_member_7_job: "Warehouse manager",
    team_member_7_tel: "(+992) 907 40 20 02",
    team_member_7_email: "badalov.a@shahinasia.com",

    team_sup_title2: "LOOKING FOR A JOB?",
    team_title2: "Our vacancies",

    team_vacancy_1: "Logistics Manager",
    team_vacancy_2: "Sales Manager",
    team_vacancy_3: "SMM Manager",
    team_vacancy_4: "Demand Planner",
    team_vacancy_5: "Inventory Control Specialist",
    team_vacancy_6: "Customs Control Manager",
    team_vacancy_7: "Delivery and Receiving Officer",
    team_vacancy_8: "Warehouse Manager",

    contacts_title: "Our contacts",
    contacts_p:
      "Contact us by phone or email, our professionals are ready to help solve your problems.",
    contacts_address_title: "Address",
    contacts_address_content: "Luchobi poyon street, Dushanbe, Tajikistan",
    contacts_tel_title: "Phone numbers",
    contacts_tel_content_1: "+992-98-812-3456",
    contacts_tel_content_2: "+992-91-812-3456",
    contacts_email_title: "Mail",
    contacts_email_content_1: "info@shahinasia.com",

    footer_copyrights: "© Copyrights 2024. All Rights Reserved.",
  },
  turkish: {
    tajik: "тоҷикӣ",
    russian: "русский",
    english: "english",
    iranian: "ایرانی",
    turkish: "türkçe",
    chinese: "中文",

    why_us: "NEDEN BİZ?",

    header_about: "hakkımızda",
    header_services: "hizmetler",
    header_map: "ülkeler",
    header_features: "avantajlar",
    header_news: "haberler",
    header_team: "takım",
    header_contacts: "kişiler",

    hat_timeline: "Pz - Cm: с 6:00 до 22:00",
    hat_address: "st. Luchobi poyon, Duşanbe, Tacikistan",

    contact_us: "BİZİ ARAYIN",

    hero_top: "Yüklerinizi Taşıyoruz",
    hero_bottom: "Hızlı ve Güvenli.",

    hero_features_sea: "Nakliye",
    hero_features_rails: "Demiryolu taşımacılığı",
    hero_features_avia: "Hava Taşımacılığı",
    hero_features_road: "Karayolu taşımacılığı",
    hero_features_warehousing: "Malların depolanması",
    hero_features_logistics: "Lojistik çözümleri",

    about_title: "Biz Kimiz?",
    about_p1:
      "- dünya çapında lojistik hizmetleri sunan genç bir nakliye şirketidir.",
    about_p1b: "SHAHIN ASIA",
    about_p2:
      "Bireysel ve grupaj taşımacılığın yanı sıra multimodal kargo taşımacılığı da dahil olmak üzere her karmaşıklıkta kargo taşımacılığı sunuyoruz. Tüzel kişiler ve bireylerle çalışarak mağazalara mal teslimatı ve kapıdan kapıya teslimat hizmetleri sunuyoruz. Malların ambalajlanması, sigortalanması ve depolanması da dahil olmak üzere kargo taşımacılığının tam döngüsünü garanti ediyoruz. SHAHIN ASIA'yı seçerek hizmetlerimizin kalitesine ve taşıyıcıların güvenilirliğine güvenebilirsiniz. Bizimle iletişime geçin ve güvenilirliğimizi ve kalitemizi kendiniz görün!",
    about_p3:
      "Bizi seçerek hizmetlerimizin kalitesine ve taşıyıcıların güvenilirliğine güvenebilirsiniz. Bizimle iletişime geçin ve güvenilirliğimizi ve kalitemizi kendiniz görün!",

    services_title: "Hizmetlerimiz",

    services_li_auto: "Karayolu taşımacılığı",
    services_li_sea: "Nakliye",
    services_li_avia: "Hava Taşımacılığı",
    services_li_rails: "Demiryolu taşımacılığı",
    services_li_express: "Ekspres ulaşım",
    services_li_packaging: "Paketleme ve depolama",
    services_li_delivery: "Teslimat ve toplama",
    services_li_warehousing: "Depolama",
    services_li_logistics: "Lojistik çözümleri",
    services_li_insurance: "Sigorta ve gümrükleme",
    services_li_trading: "Ticaret acentesi",
    services_li_multimodal: "Multimodal taşımacılık",

    map_title: "Teslimat yaptığımız ülkeler:",

    map_tj: "Tacikistan",
    map_cn: "Çin",
    map_tr: "Türkiye",
    map_ir: "İran",
    map_ru: "Rusya",
    map_kz: "Kazakistan",
    map_ae: "BAE",
    map_us: "Amerika Birleşik Devletleri",
    map_in: "Hindistan",
    map_pk: "Pakistan",
    map_eu: "Avrupa",
    map_other: "ve diğerleri...",

    partners_title: "Bize güveniyorlar",

    partners_1_fullname: "Сафоев Фируз",
    partners_1_job: 'Директор "Армугони Эрон"',
    partners_1_citation:
      "Мы сотрудничаем с данной логистической компанией уже несколько лет и всегда остаемся довольными качеством и оперативностью их услуг. Команда профессионалов всегда готова помочь и предложить оптимальные решения для наших логистических задач.",

    partners_2_fullname: "Сомон Бакиев",
    partners_2_job: 'Менеджер по продажам в "PEK. Hayat group"',
    partners_2_citation:
      "На протяжении нескольких лет мы сотрудничаем с логистической компанией, и мы полностью удовлетворены качеством их услуг. Надежные и оперативные поставки, а также высокий уровень обслуживания делают их нашим долгосрочным партнером.",

    features_title: "Bizim avantajlarımız",

    features_professionalism_title:
      "Lojistik uzmanlarından oluşan profesyonel ekip.",
    features_professionalism_description:
      "Deneyimli ve bilgili lojistik uzmanları sorularınızı cevaplayacak",

    features_experience_title: "Çeşitli kargo türlerinde zengin deneyim.",
    features_experience_description:
      "Müşterilerimiz en büyük ticaret, imalat ve inşaat işletmeleridir",

    features_approach_title: "Her müşteriye bireysel yaklaşım.",
    features_approach_description:
      "Tek bir yönetici, müşterinin nakliye ve lojistik alanındaki tüm ihtiyaçlarını karşılar",

    features_responsibility_title: "Sorumluluk.",
    features_responsibility_description:
      "Büyümek için müşterilerimize güvenebilecekleri bir güvenlik duygusu sağlıyoruz",

    team_title: "Bizim takım",
    team_member_1_fullname: "Amir Shanehei",
    team_member_1_job: "CEO Yardımcısı",
    team_member_1_tel: "(+992) 888 88 86 65",
    team_member_1_tel_extra: "(+98) 912 307 88 38",
    team_member_1_email: "shanehei@shahinasia.com",
    team_member_2_fullname: "Umedjon Hasanzoda",
    team_member_2_job: "Kurucu ve CEO",
    team_member_2_tel: "-",
    team_member_2_email: "hasanzoda@shahinasia.com",
    team_member_3_fullname: "Hudyorov Jamshed",
    team_member_3_job: "Satış Müdürü",
    team_member_3_tel: "(+992) 970 48 65 48",
    team_member_3_email: "khudoyarov.j@shahinasia.com",
    team_member_4_fullname: "Khudyorov Shokhrukh",
    team_member_4_job: "Lojistik Yöneticisi",
    team_member_4_tel: "(+992) 920 20 20 20",
    team_member_4_email: "khudoyarov.sh@shahinasia.com",
    team_member_5_fullname: "Khudoyorov Dilovar",
    team_member_5_job: "Satış Müdürü",
    team_member_5_tel: "(+992) 100 22 22 65",
    team_member_5_email: "khudoyarov.d@shahinasia.com",
    team_member_6_fullname: "Khudyorov Somon",
    team_member_6_job: "Lojistik Yöneticisi",
    team_member_6_tel: "(+992) 220 20 20 20",
    team_member_6_email: "khudoyarov.s@shahinasia.com",
    team_member_7_fullname: "Badalov Abdullokh",
    team_member_7_job: "Depo yöneticisi",
    team_member_7_tel: "(+992) 907 40 20 02",
    team_member_7_email: "badalov.a@shahinasia.com",

    team_sup_title2: "İŞ ARAMAK?",
    team_title2: "Boş pozisyonlarımız",

    team_vacancy_1: "Lojistik Yöneticisi",
    team_vacancy_2: "Satış Müdürü",
    team_vacancy_3: "SMM Yöneticisi",
    team_vacancy_4: "Talep Planlayıcı",
    team_vacancy_5: "Envanter Kontrol Uzmanı",
    team_vacancy_6: "Gümrük Kontrol Müdürü",
    team_vacancy_7: "Teslimat ve Teslim Alma Memuru",
    team_vacancy_8: "Depo yöneticisi",

    contacts_title: "Bağlantılarımız",
    contacts_p:
      "Bize telefon veya e-posta yoluyla ulaşın; profesyonellerimiz sorunlarınızı çözmeye yardımcı olmaya hazırdır.",
    contacts_address_title: "Adres",
    contacts_address_content: "st. Luchobi poyon, Duşanbe, Tacikistan",
    contacts_tel_title: "Telefon numaraları",
    contacts_tel_content_1: "+992-98-812-3456",
    contacts_tel_content_2: "+992-91-812-3456",
    contacts_email_title: "Posta",
    contacts_email_content_1: "info@shahinasia.com",

    footer_copyrights: "© Copyrights 2024. All Rights Reserved.",
  },
  chinese: {
    tajik: "тоҷикӣ",
    russian: "русский",
    english: "english",
    iranian: "ایرانی",
    turkish: "türkçe",
    chinese: "中文",

    why_us: "為什麼是我們?",

    header_about: "關於我們",
    header_services: "服務",
    header_map: "國家",
    header_features: "優點",
    header_news: "訊息",
    header_team: "團隊",
    header_contacts: "聯絡人",

    hat_timeline: "週一 - 星期五: 6:00 至 22:00",
    hat_address: "英石。 Luchobi poyon, 杜尚別, 塔吉克斯坦",

    contact_us: "致電我們",

    hero_top: "我們承運您的貨物",
    hero_bottom: "快速又安全.",

    hero_features_sea: "船運",
    hero_features_rails: "軌道交通",
    hero_features_avia: "航空運輸",
    hero_features_road: "公路運輸",
    hero_features_warehousing: "貨物儲存",
    hero_features_logistics: "物流解決方案",

    about_title: "我們是誰?",
    about_p1: "- 是一家年轻的运输公司，在世界各地提供物流服务",
    about_p1b: "SHAHIN ASIA",
    about_p2:
      "我们提供任何复杂程度的货物运输，包括散客和拼装运输，以及多式联运。 我们与法人实体和个人合作，提供商店送货服务和送货上门服务。 我们保证货物运输的全周期，包括货物的包装、保险和储存。 通过选择 SHAHIN ASIA，您可以对我们的服务质量和承运商的可靠性充满信心。 联系我们，亲眼看看我们的可靠性和质量！",
    about_p3:
      "通过选择我们，您可以对我们的服务质量和承运商的可靠性充满信心。 联系我们，亲眼看看我们的可靠性和质量",

    services_title: "我們的服務",

    services_li_auto: "公路運輸",
    services_li_sea: "船運",
    services_li_avia: "航空運輸",
    services_li_rails: "軌道交通",
    services_li_express: "快遞運輸",
    services_li_packaging: "包裝及貯存",
    services_li_delivery: "交付和收集",
    services_li_warehousing: "倉儲",
    services_li_logistics: "物流解決方案",
    services_li_insurance: "保險和清關",
    services_li_trading: "貿易代理",
    services_li_multimodal: "多式聯運",

    map_title: "我們送貨的國家:",

    map_tj: "塔吉克",
    map_cn: "中國",
    map_tr: "土耳其",
    map_ir: "伊朗",
    map_ru: "俄羅斯",
    map_kz: "哈薩克",
    map_ae: "阿聯酋",
    map_us: "美國",
    map_in: "印度",
    map_pk: "巴基斯坦",
    map_eu: "歐洲",
    map_other: "和別的...",

    partners_title: "他們信任我們",

    partners_1_fullname: "Сафоев Фируз",
    partners_1_job: 'Директор "Армугони Эрон"',
    partners_1_citation:
      "Мы сотрудничаем с данной логистической компанией уже несколько лет и всегда остаемся довольными качеством и оперативностью их услуг. Команда профессионалов всегда готова помочь и предложить оптимальные решения для наших логистических задач.",

    partners_2_fullname: "Сомон Бакиев",
    partners_2_job: 'Менеджер по продажам в "PEK. Hayat group"',
    partners_2_citation:
      "На протяжении нескольких лет мы сотрудничаем с логистической компанией, и мы полностью удовлетворены качеством их услуг. Надежные и оперативные поставки, а также высокий уровень обслуживания делают их нашим долгосрочным партнером.",

    features_title: "我們的優勢",

    features_professionalism_title: "專業的物流專家團隊.",
    features_professionalism_description:
      "經驗豐富、知識淵博的物流專家將為您解答問題",

    features_experience_title: "豐富的各類貨物經驗.",
    features_experience_description: "我們的客戶是最大的貿易、製造和建築企業",

    features_approach_title: "對每個客戶採取單獨的方法.",
    features_approach_description:
      "一名經理可滿足客戶在運輸和物流領域的所有需求",

    features_responsibility_title: "責任",
    features_responsibility_description:
      "為了成長，我們明確地為客戶提供可以信賴的安全感",

    team_title: "我們的隊伍",
    team_member_1_fullname: "阿米尔·沙内海",
    team_member_1_job: "副总裁",
    team_member_1_tel: "(+992) 888 88 86 65",
    team_member_1_tel_extra: "(+98) 912 307 88 38",
    team_member_1_email: "shanehei@shahinasia.com",
    team_member_2_fullname: "乌梅琼·哈桑佐达",
    team_member_2_job: "创始人兼首席执行官",
    team_member_2_tel: "-",
    team_member_2_email: "hasanzoda@shahinasia.com",
    team_member_3_fullname: "胡多約羅夫·賈姆謝德",
    team_member_3_job: "銷售經理",
    team_member_3_tel: "(+992) 970 48 65 48",
    team_member_3_email: "khudoyarov.j@shahinasia.com",
    team_member_4_fullname: "胡多約羅夫·肖赫魯赫",
    team_member_4_job: "物流經理",
    team_member_4_tel: "(+992) 920 20 20 20",
    team_member_4_email: "khudoyarov.sh@shahinasia.com",
    team_member_5_fullname: "胡多約羅夫·季洛瓦爾",
    team_member_5_job: "銷售經理",
    team_member_5_tel: "(+992) 100 22 22 65",
    team_member_5_email: "khudoyarov.d@shahinasia.com",
    team_member_6_fullname: "胡多約羅夫·索蒙",
    team_member_6_job: "物流經理",
    team_member_6_tel: "(+992) 220 20 20 20",
    team_member_6_email: "khudoyarov.s@shahinasia.com",
    team_member_7_fullname: "巴達洛夫·阿卜杜洛克",
    team_member_7_job: "倉庫經理",
    team_member_7_tel: "(+992) 907 40 20 02",
    team_member_7_email: "badalov.a@shahinasia.com",

    team_sup_title2: "找工作?",
    team_title2: "我們的職位空缺",

    team_vacancy_1: "物流經理",
    team_vacancy_2: "銷售經理",
    team_vacancy_3: "SMM經理",
    team_vacancy_4: "需求計劃",
    team_vacancy_5: "庫存控制專家",
    team_vacancy_6: "海關管制經理",
    team_vacancy_7: "送貨及收貨員",
    team_vacancy_8: "倉庫經理",

    contacts_title: "我們的聯絡方式",
    contacts_p:
      "透過電話或電子郵件聯絡我們，我們的專業人員隨時準備協助您解決問題.",
    contacts_address_title: "地址",
    contacts_address_content: "英石。 Luchobi poyon, 杜尚別, 塔吉克斯坦",
    contacts_tel_title: "電話號碼",
    contacts_tel_content_1: "+992-98-812-3456",
    contacts_tel_content_2: "+992-91-812-3456",
    contacts_email_title: "郵件",
    contacts_email_content_1: "info@shahinasia.com",

    footer_copyrights: "© Copyrights 2024. All Rights Reserved.",
  },
};

export const translationStore = observable({
  get translationMap() {
    return translationMap[this.language];
  },

  language: "russian",
  // language: localStorage.getItem("language") || "russian",
  setLanguage(language) {
    this.language = language;
    localStorage.setItem("language", language);
  },
});
