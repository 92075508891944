import React from "react";

// components
import Navbar from "../../components/Navbar";
import SimpleSlider from "../../components/hero";
import Features from "../../components/features";
import AboutSection3 from "../../components/about3";
import ServiceArea3 from "../../components/ServiceArea3";
import WpoFeatures from "../../components/wpofeatures";
// import Testimonial from "../../components/testimonial";
import Contactpage from "../../components/Contactpage";
// import BlogSection from "../../components/BlogSection";
import FooterSection from "../../components/Footer";
import { MapSection } from "../../components/MapSection";
import TeamSection from "../../components/Team";
import { PartnersLikeMapSection } from "../../components/PartnersLikeMapSection";

const Homepage3 = () => {
  return (
    <div>
      <Navbar />
      <SimpleSlider />
      <Features tbclass={"wpo-section-area-3"} />
      <div id="about">
        <AboutSection3 />
      </div>
      <div id="services">
        <ServiceArea3 />
      </div>
      <div id="map">
        <MapSection />
      </div>
      <div id="partners">
        {/* <Testimonial /> */}
        <PartnersLikeMapSection />
      </div>

      <div id="features">
        <WpoFeatures />
      </div>
      <div id="team">
        <TeamSection />
      </div>

      {/* <div id="news">
        <BlogSection />
      </div> */}
      <div id="contacts">
        <Contactpage />
      </div>
      <FooterSection />
    </div>
  );
};

export default Homepage3;
