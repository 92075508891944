import React from "react";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { translationStore } from "../../custom/translation.store";
import "./style.css";
import { observer } from "mobx-react-lite";

function SimpleSlider() {
  const t = translationStore.translationMap;

  const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <section className="hero hero-slider-wrapper hero-style-1">
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="slide1 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-9 slide-caption">
                  <h2>
                    <span>{t.hero_top}</span> <br />
                    <span>{t.hero_bottom}</span>
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <a href="tel:+992918616161">{t.contact_us}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide2 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-9 slide-caption">
                  <h2>
                    <span>{t.hero_top}</span> <br />
                    <span>{t.hero_bottom}</span>
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <a href="tel:+992918616161">{t.contact_us}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide3 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-9 slide-caption">
                  <h2>
                    <span>{t.hero_top}</span> <br />
                    <span>{t.hero_bottom}</span>
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <a href="tel:+992918616161">{t.contact_us}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide5 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-9 slide-caption">
                  <h2>
                    <span>{t.hero_top}</span> <br />
                    <span>{t.hero_bottom}</span>
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <a href="tel:+992918616161">{t.contact_us}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide6 slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-9 slide-caption">
                  <h2>
                    <span>{t.hero_top}</span> <br />
                    <span>{t.hero_bottom}</span>
                  </h2>
                  <div className="btns">
                    <div className="btn-style">
                      <a href="tel:+992918616161">{t.contact_us}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default observer(SimpleSlider);
