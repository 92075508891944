import React from "react";
export const FlagForRussiaSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    aria-hidden="true"
    className="flag-for-flag-russia-svgrepo-com_svg__iconify flag-for-flag-russia-svgrepo-com_svg__iconify--twemoji"
    viewBox="0 0 36 36"
    {...props}
  >
    <path fill="#CE2028" d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-4h36z" />
    <path fill="#22408C" d="M0 13h36v10H0z" />
    <path fill="#EEE" d="M32 5H4a4 4 0 0 0-4 4v4h36V9a4 4 0 0 0-4-4" />
  </svg>
);
