import React from "react";
import "./styles.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

export const MapSection = observer(() => {
  const t = translationStore.translationMap;

  return (
    <div className="custom-map-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6"></div>
          <div
            className="col-lg-6 about-wrap "
            style={{
              background: "#142440aa",
              padding: "50px",
              color: "white",
              backdropFilter: "blur(1px)",
            }}
          >
            <h2
              style={{
                fontSize: 36,
                fontWeight: 700,
              }}
            >
              {t.map_title}
            </h2>

            <ul>
              <li>{t.map_cn}</li>
              <li>{t.map_tr}</li>
              <li>{t.map_eu}</li>
              <li>{t.map_us}</li>
              <li>{t.map_ru}</li>
              <li>{t.map_ae}</li>
              <li>{t.map_ir}</li>
              <li>{t.map_kz}</li>
              <li>{t.map_in}</li>
              <li>{t.map_pk}</li>
              <li>{t.map_tj}</li>
              <li>{t.map_other}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
