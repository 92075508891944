import React from "react";
import feimg from "../../images/features/advantages.png";
import "./style.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

const WpoFeatures = () => {
  const t = translationStore.translationMap;

  return (
    <div className="wpo-features-area">
      <div className="container">
        <div className="wpo-section-title text-center">
          <span>{t.why_us}</span>
          <h2>{t.features_title}</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="wpo-features-item-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="wpo-features-icon2">
                    <i className="fi flaticon-plane"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t.features_professionalism_title}</h3>
                    <p>{t.features_professionalism_description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="feature-icon3">
                    <i className="fi flaticon-truck"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t.features_experience_title}</h3>
                    <p>{t.features_experience_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="wpo-features-item">
              <div className="wpo-feature-img">
                <img src={feimg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="wpo-features-item">
              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="wpo-features-icon">
                    <i className="fi flaticon-ship"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t.features_approach_title}</h3>
                    <p>{t.features_approach_description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="wpo-feature-wrap">
                  <div className="wpo-features-icon">
                    <i className="fi flaticon-truck-1"></i>
                  </div>
                  <div className="wpo-features-text">
                    <h3>{t.features_responsibility_title}</h3>
                    <p>{t.features_responsibility_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(WpoFeatures);
