import React from "react";
import "./style.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

const ServiceArea3 = () => {
  const t = translationStore.translationMap;

  return (
    <div className="service-style-3">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t.why_us}</span>
            <h2>{t.services_title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-truck"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_auto}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-ship-1"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_sea}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-plane"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_avia}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-train"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_rails}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-shield"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_insurance}</h3>
                <p>...</p>
              </div>
            </div>
          </div>{" "}
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-construction-tool-vehicle-with-crane-lifting-materials"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_logistics}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-express"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_express}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-box"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_packaging}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-deliveryman"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_delivery}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-trolley"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_warehousing}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-agency"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_trading}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="wpo-service-item">
              <div className="wpo-service-icon">
                <i className="fi flaticon-multi"></i>
              </div>
              <div className="service-text">
                <h3>{t.services_li_multimodal}</h3>
                <p>...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ServiceArea3);
