import { observer } from "mobx-react-lite";
import React from "react";
import { SUPPORTED_LANGUAGES, translationStore } from "./translation.store";
import { FlagForTajikistanSVG } from "./flags/FlagForTajikistanSVG";
import { FlagForRussiaSVG } from "./flags/FlagForRussiaSVG";
import { FlagForUnitedStatesSVG } from "./flags/FlagForUnitedStatesSVG";
import { FlagForIranSVG } from "./flags/FlagForIranSVG";
import { FlagForTurkeySVG } from "./flags/FlagForTurkeySVG";
import { FlagForChinaSVG } from "./flags/FlagForChinaSVG";

import "./TranslationBar.css";

const languageToFlagIconRecord = {
  tajik: FlagForTajikistanSVG,
  russian: FlagForRussiaSVG,
  english: FlagForUnitedStatesSVG,
  iranian: FlagForIranSVG,
  turkish: FlagForTurkeySVG,
  chinese: FlagForChinaSVG,
};

export const TranslationBar = observer(() => {
  const t = translationStore.translationMap;

  return (
    <div
      className="btn-group translation-bar-button-group"
      // style={
      // {
      //   display: "flex",
      //   justifyContent: "flex-start",
      //   alignContent: "flex-end",
      // }
      // }
    >
      {SUPPORTED_LANGUAGES.map((language) => (
        <button
          type="button"
          style={{
            padding: "0 0.75rem",
          }}
          className={
            "btn btn-link translation-bar-button"
            // (" " +
            //   (language === translationStore.language
            //     ? "btn-primary"
            //     : "btn-info"))
          }
          disabled={language === translationStore.language}
          onClick={() => {
            if (["iranian"].includes(language)) return;
            translationStore.setLanguage(language);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // color: "white",
              textTransform: "uppercase",
              fontSize: "0.8em",
            }}
          >
            <span style={{ fontSize: "30px" }}>
              {React.createElement(languageToFlagIconRecord[language])}
            </span>
            <span>{t[language]}</span>
          </div>
        </button>
      ))}
    </div>
  );
});
