import React from "react";
import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialImg_1 from "../../images/testimonial/2.jpg";
import { observer } from "mobx-react-lite";
import { translationStore } from "../../custom/translation.store";

function Testimonial() {
  const t = translationStore.translationMap;

  const partners = [
    {
      fullname: t.partners_1_fullname,
      job: t.partners_1_job,
      img: testimonialImg_1,
      citation: t.partners_1_citation,
    },
    {
      fullname: t.partners_2_fullname,
      job: t.partners_2_job,
      img: testimonialImg_1,
      citation: t.partners_2_citation,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    arrows: false,
    margin: 50,
    autoplay: true,
    slidesToScroll: 1,
    centerPadding: 30,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="wpo-testimonial-area">
      <div className="container">
        <div className="wpo-section-title text-center">
          <span style={{ color: "#34ccff", position: "relative" }}>
            {t.why_us}
          </span>
          <h2 style={{ color: "white" }}>{t.partners_title}</h2>
        </div>
        {/* <div className="row">
          <div className="col-lg-3">
            <img src="" />
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="wpo-testimonial-active owl-carousel">
              <Slider {...settings}>
                {partners.map((el) => (
                  <div
                    className="wpo-testimonial-wrap"
                    style={{
                      margin: 10,
                    }}
                  >
                    <div className="wpo-testimonial-img">
                      <img src={el.img} alt="" />
                    </div>
                    <div className="wpo-testimonial-item">
                      <div className="wpo-testimonial-content">
                        <p>{el.citation}</p>
                        <h4>{el.fullname}</h4>
                        <span>{el.job}</span>
                      </div>
                    </div>
                    <div className="test-c d-none d-lg-block"></div>
                    <div className="test-b d-none d-lg-block"></div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Testimonial);
