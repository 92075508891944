import React from "react";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team_1 from "../../images/team/1.jpg";
// import team_2 from "../../images/team/2.jpg";
// import team_3 from "../../images/team/3.jpg";
import { translationStore } from "../../custom/translation.store";

import logisticsImg from "../../images/team/custom/logistics.png";
import salesImg from "../../images/team/custom/sales.png";
import smmImg from "../../images/team/custom/smm.png";
import plannerImg from "../../images/team/custom/planner.png";
import { observer } from "mobx-react-lite";

export default observer(function TeamSection() {
  const t = translationStore.translationMap;

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    autoplay: true,
    speed: 1000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const members = [
    {
      fullname: t.team_member_2_fullname,
      job: t.team_member_2_job,
      avatar: team_1,
      tel: t.team_member_2_tel,
      email: t.team_member_2_email,
    },
    {
      fullname: t.team_member_1_fullname,
      job: t.team_member_1_job,
      avatar: team_1,
      tel: t.team_member_1_tel,
      tel2: t.team_member_1_tel_extra,
      email: t.team_member_1_email,
    },
    {
      fullname: t.team_member_3_fullname,
      job: t.team_member_3_job,
      avatar: team_1,
      tel: t.team_member_3_tel,
      email: t.team_member_3_email,
    },
    {
      fullname: t.team_member_4_fullname,
      job: t.team_member_4_job,
      avatar: team_1,
      tel: t.team_member_4_tel,
      email: t.team_member_4_email,
    },
    {
      fullname: t.team_member_5_fullname,
      job: t.team_member_5_job,
      avatar: team_1,
      tel: t.team_member_5_tel,
      email: t.team_member_5_email,
    },
    {
      fullname: t.team_member_6_fullname,
      job: t.team_member_6_job,
      avatar: team_1,
      tel: t.team_member_6_tel,
      email: t.team_member_6_email,
    },
    {
      fullname: t.team_member_7_fullname,
      job: t.team_member_7_job,
      avatar: team_1,
      tel: t.team_member_7_tel,
      email: t.team_member_7_email,
    },
  ];

  const vacancies = [
    { name: t.team_vacancy_1, img: logisticsImg },
    { name: t.team_vacancy_2, img: salesImg },
    { name: t.team_vacancy_3, img: smmImg },
    { name: t.team_vacancy_4, img: plannerImg },
    { name: t.team_vacancy_5, img: team_1 },
    { name: t.team_vacancy_6, img: team_1 },
    { name: t.team_vacancy_7, img: team_1 },
  ];

  return (
    <div className="wpo-team-area-2">
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t.why_us}</span>
            <h2>{t.team_title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              {members.map((el) => (
                <div>
                  <div className="wpo-team-single" style={{ padding: 20 }}>
                    <div className="wpo-team-img">
                      <img src={el.avatar} alt="" />
                    </div>
                    <div
                      className="wpo-team-content"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h4>{el.fullname}</h4>
                      <span>{el.job}</span>
                      <span>
                        {el.tel} {el.tel2 && <span> | {el.tel2}</span>}
                      </span>
                      {/* <span>
                        {el.tel2} {"\u00A0"}
                      </span> */}
                      <span>{el.email}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          {/* <div className="col-lg-4 col-md-6 col-12">
            <div className="wpo-team-single">
              <div className="wpo-team-img">
                <img src={team_3} alt="" />
                <div className="social-1st">
                  <ul>
                    <li>
                      <Link to="/">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="wpo-team-content">
                <h4>Simon D’soza</h4>
                <span>Logistic Maneger</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div style={{ marginTop: 100 }}>
        <div className="wpo-section-title text-center">
          <span>{t.team_sup_title2}</span>
          <h2>{t.team_title2}</h2>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                {vacancies.map((el) => (
                  <div>
                    <div className="wpo-team-single" style={{ padding: 20 }}>
                      <div className="wpo-team-img">
                        <img src={el.img} alt="" />
                      </div>
                      <div className="wpo-team-content">
                        <h4>{el.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
