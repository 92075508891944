import React from "react";
import HashLink from "react-anchor-link-smooth-scroll";

import "./style.css";
import { translationStore } from "../../custom/translation.store";
import { observer } from "mobx-react-lite";

const Features = (props) => {
  const t = translationStore.translationMap;

  return (
    <div className={`wpo-section-area ${props.tbclass}`}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-truck"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">{t.hero_features_road}</HashLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-train"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">{t.hero_features_rails}</HashLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-plane"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">{t.hero_features_avia}</HashLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-ship"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">{t.hero_features_sea}</HashLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-warehouse"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">
                    {t.hero_features_warehousing}
                  </HashLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 col-d">
            <div className="wpo-section-item">
              <div className="wpo-section-icon">
                <i className="fi flaticon-crane"></i>
              </div>
              <div className="wpo-section-content">
                <p>
                  <HashLink href="#services">
                    {t.hero_features_logistics}
                  </HashLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Features);
